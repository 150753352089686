require("./bootstrap")

import "core-js/stable"
import "regenerator-runtime/runtime"

// Base
import Vue from "vue/dist/vue.esm"
import store from "./store"
import I18n from "./i18n.js.erb"

import AirbnbStyleDatepicker from "vue-airbnb-style-datepicker"

var raw_days_short = I18n.t("date.abbr_day_names")
raw_days_short.push(raw_days_short.shift())
const days_short = raw_days_short

var raw_days = I18n.t("date.day_names")
raw_days.push(raw_days.shift())
const days = raw_days

var raw_months = I18n.t("date.month_names")
raw_months.shift()
const month_names = raw_months

const datepickerOptions = {
  sundayFirst: false,
  days: days,
  daysShort: days_short,
  monthNames: month_names,
  colors: {
    selected: "#003041",
    inRange: "#4D6E7A",
    selectedText: "#FFF",
    text: "#4A5568",
    inRangeBorder: "#003041",
    disabled: "#FFF",
    hoveredInRange: "#4D6E7A"
  },
  texts: {
    apply: I18n.t("app.confirm"),
    cancel: I18n.t("app.cancel")
  }
}

Vue.use(AirbnbStyleDatepicker, datepickerOptions)
// Vue.component("date-picker", AirbnbStyleDatepicker)


import VuePaginate from "vue-paginate"
Vue.use(VuePaginate)

// Custom Components
import HotelAvailabilities from "./components/HotelAvailabilities"
Vue.component("hotel-availabilities", HotelAvailabilities)

import HotelRooms from "./components/HotelRooms"
Vue.component("hotel-rooms", HotelRooms)

import HotelDatePicker from "./components/HotelDatePicker"
Vue.component("hotel-date-picker", HotelDatePicker)

import HutDatePicker from "./components/HutDatePicker"
Vue.component("hut-date-picker", HutDatePicker)

import ServiceList from "./components/ServiceList"
Vue.component("service-list", ServiceList)

import Service from "./components/Service"
Vue.component("service", Service)

import SpecialList from "./components/SpecialList"
Vue.component("special-list", SpecialList)

import Special from "./components/Special"
Vue.component("special", Special)

import PackageList from "./components/PackageList"
Vue.component("package-list", PackageList)

import Package from "./components/Package"
Vue.component("package", Package)

import Select from "./components/Select"
Vue.component("select2", Select)

import Modal from "./components/Modal"
Vue.component("modal", Modal)

import Calendar from "./components/Calendar"
Vue.component("calendar", Calendar)

import Calendar2 from "./components/Calendar2"
Vue.component("calendar2", Calendar2)

import CalendarDay from "./components/CalendarDay"
Vue.component("calendar-day", CalendarDay)

import Tabs from "./components/Tabs"
Vue.component("tabs", Tabs)

import Tab from "./components/Tab"
Vue.component("tab", Tab)

Vue.mixin({
  methods: {
    translate: function(key) {
      // TODO this seems hacky
      if (window.location.pathname.startsWith("/en")) {
        I18n.locale = "en"
      } else {
        I18n.locale = "de"
      }

      return I18n.t(key)
    }
  }
})

let data = {
  gon: window.gon
}

let methods = {
  openModal: function(name) {
    this.$refs[name].open()
  }
}

let computed = {
  room_summary() {
    return store.state.room_summary
  },

  selected_rooms() {
    return store.state.selected_rooms
  },

  selected_rooms_arrival() {
    if (store.state.selected_rooms.length) {
      return store.state.selected_rooms[0].arrival_date
    }
  },

  selected_rooms_departure() {
    if (store.state.selected_rooms.length) {
      return store.state.selected_rooms[0].departure_date
    }
  },

  selected_rooms_total() {
    let val = (_.sumBy(store.state.selected_rooms, o => parseInt(o.price.cents * o.number_of_rooms_selected)) / 100)
    return currency(val, { decimal: ",", separator: "" }).format()
  },

  selected_rooms_total_cents() {
    return _.sumBy(store.state.selected_rooms, o => parseInt(o.price.cents * o.number_of_rooms_selected))
  },

  selected_rooms_hut_total() {
    return ((_.sumBy(store.state.selected_rooms, o => parseInt(o.price.cents * o.number_of_rooms_selected)) + 2500) / 100).toFixed(2)
  },

  selected_rooms_hut_total_cents() {
    return _.sumBy(store.state.selected_rooms, o => parseInt(o.price.cents * o.number_of_rooms_selected)) + 2500
  }
}

document.addEventListener("DOMContentLoaded", () => {
  var vm = new Vue({
    el: "#app",
    data: data,
    computed: computed,
    methods: methods
  })
})
